import React from 'react'
import { graphql } from 'gatsby'
import { TopicLayout } from "../layouts/"

export const query = graphql`
    query TopicTemplateQuery($id: String!) {
        model: sanityTopic(id: { eq: $id }) {
            ...TopicTemplate
        }
    }
`

const TopicTemplate = (props) => {
    return (
        <TopicLayout {...props} />
    )


}

export default TopicTemplate
